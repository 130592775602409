<template>
  <div class="OnboardingStep2">
    <div class="is-flex is-aligned-center has-text-centered is-column m-b-xl">
      <div class="title has-text-centered p-v-l is-4">
        {{ $t(`pages.onboarding.step_2.title`) }}
      </div>

      <div data-testid="OnboardingStep2Description" class="m-b-l">
        <div v-if="!hasImageLoaded">
          {{ $t('pages.onboarding.step_2.description') }}
        </div>

        <div v-else>
          {{ $t('pages.onboarding.step_2.description_edit_mode') }}
        </div>
      </div>

      <div class="OnboardingStep2__imageUploadWrapper is-position-relative">
        <form-field
          :validator="validator.newsroomLogo.original"
          :attribute="$t('general.logo')"
        >
          <template slot="control">
            <template v-if="isEditing">
              <el-tooltip placement="top">
                <span class="is-size-4-mobile OnboardingStep2__suggestedImage-Tooltip"><i class="hf hf-question-circle" /></span>
                <div slot="content">
                  <div class="is-hidden-tablet">
                    {{ $t('pages.onboarding.step_2.suggested_image_tooltip_mobile') }}
                  </div>
                  <div class="is-hidden-mobile">
                    {{ $t('pages.onboarding.step_2.suggested_image_tooltip_desktop') }}
                  </div>
                </div>
              </el-tooltip>
            </template>

            <croppie-wrapper
              ref="newsroomLogo"
              v-model="newsroomLogo"
              :min-width="100"
              :min-height="100"
              :edit-on-change="false"
              :enforce-boundary="false"
              :settings="croppieSettings"
              :crop-options="croppieSettings.cropOptions"
              is-circle
              text-size="7"
              entity="NewsroomsLogo"
              upload-url="/newsrooms/logos"
              @save="uploadImage"
              @edit="isEditing = $event"
              @cancel="hasImageLoaded = false"
              @removed="hasImageLoaded = false"
              @file-changed="hasImageLoaded = true"
            >
              <span> {{ $t('forms.upload_logo_image') }} </span>
            </croppie-wrapper>
          </template>
        </form-field>
      </div>
    </div>

    <div class="buttons no-wrap is-aligned-end OnboardingStep__actions">
      <v-button
        class="is-dark is-width-full-mobile"
        data-testid="OnboardingStep2_BackButton"
        @click="goPrev"
      >
        {{ $t('general.prev') }}
      </v-button>

      <v-button
        :loading="isUploading"
        class="is-primary is-width-full-mobile"
        data-testid="OnboardingStep2_NextButton"
        @click="uploadImageAndGoNext"
      >
        {{ $t(hasImageLoaded ? 'general.next': 'general.skip') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import OnboardingStepPrototype, { createComputedFormField } from '../OnboardingStepPrototype'
import { croppieDefaultSettings } from '@hypefactors/shared/js/factories/croppie'

export default {
  name: 'OnboardingStep2',

  extends: OnboardingStepPrototype,

  data () {
    return {
      croppieSettings: croppieDefaultSettings(160, 160),

      isUploading: false,

      // Determines if we are in Croppie Edit mode or not
      isEditing: false,

      // Determines if we have selected an image in croppie
      hasImageLoaded: false
    }
  },

  computed: {
    newsroomLogo: createComputedFormField('newsroomLogo'),

    isLogoEmpty () {
      return !this.newsroomLogo.original
    }
  },

  methods: {
    async uploadImage () {
      this.isUploading = true

      try {
        await this.$refs.newsroomLogo.uploadImage()
      } catch (error) {
        this.$displayRequestError(error, this.$t('errors.could_not_upload_image'))
        throw error
      }

      this.isUploading = false
    },

    async uploadImageAndGoNext () {
      try {
        this.hasImageLoaded && await this.uploadImage()
        this.proceed()
      } catch (err) { }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.OnboardingStep2__imageUploadWrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  @include tablet {
    flex-flow: row;
  }
}

.OnboardingStep2__suggestedImage {
  width: 150px;

  &:hover {
    @include shadow-1()
  }

  &-Tooltip {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
